<template>
  <div class="login_body">
    <div class="login_impor">
      <div class="login_bt_head">
        <img src="@/assets/images/login_logo.png" />
      </div>
      <div class="login_bt_table">
        <div class="login_bt_table_cell">
          <div class="login_bt_impor">
            <img src="@/assets/images/login_left.jpg" class="login_left">
            <div class="shiyong">
              <!-- <div class="shiyong_ewm"><img src="@/assets/images/wx.jpg" >扫码联系微信客服</div> -->
              <h1>用户注册</h1>
              <!-- <div class="shiyong_info">
                <h3>东都MMS服务协议</h3>
                <p>1 请务必认真阅读和理解本《东都MMS服务协议》(以下简称《协议》)中规定的所有权利和限制。</p>
                <p>2 本软件经过详细的测试，但不能保证与所有的软硬件系统完全兼容，不能保证本软件完全没有错误。如果出现不兼容及软件错误的情况，用户可联系客服<span class="red">(微信号dongdumms)</span>获取技术支持。</p>
                <p>3 除非另有明确规定，东都MMS平台所推出的新功能、新服务，均无条件的使用本协议。</p>
                <p>4 东都MMS平台保留在任何时候修改本协议条款的权利，且无需另行通知，用户在使用服务时应关注并遵守；当发生有关争议时，以最新的协议文本为准。</p>
                <p class="xiaotou">二、用户注册</p>
                <p>1、用户应当同意本协议的条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击“确认”按钮即表示用户完全接受本协议项下的全部条款。</p>
                <p>2、用户在使用本平台服务过程中应保证各项服务业务所需信息的真实性，如果因确认的信息不真实而引起的问题，并对问题发生所带来的后果，东都MMS平台不负任何责任。</p>
                <p>3、注册时，请您选择填写用户名和密码，并按页面提示提交相关信息。您负有对用户名和密码保密的义务，并对该用户名和密码下发生的所有活动承担责任。请您不要把用户名、密码和注册时填写的个人信息提供给第三方网站或他人使用。</p>
                <p>4、在您使用东都MMS平台时，本平台会提示、收集您的部分个人信息资料，本平台不对外公开或向第三方提供用户的信息资料。仅会将这些资料用于：改进为您提供的服务及网页内容。</p>
                <p>5、您可以选择进一步完善本产品账号信息，如填写生日、性别等信息，但如果您不提供这些信息，也不会影响您使用本产品提供的基础服务。</p>
                <p class="xiaotou">三、服务内容</p>
                <p>1. 东都MMS平台免费为用户提供服务，用户使用网络下载服务软件产生的流量费用需自行承担。</p>
                <p>2. 除非本服务协议另有其它明示规定，本平台所推出的新产品、新功能、新服务，均受到本服务协议之规范。</p>
                <p>3. 用户同意使用本平台进行员工管理、会员管理等服务，由本平台根据实际情况提供。用户的使用行为视为其对服务条款以及本平台在服务中发出的各类公告的同意。</p>
                <p class="xiaotou">四、服务变更、中断或终止</p>
                <p>1. 重东都MMS平台会定期或不定期的对本平台系统进行检修或维护，无需为此承担任何责任。</p>
                <p>2. 鉴于网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及东都MMS平台无法控制的情形），用户同意东都MMS平台有权随时中断或终止部分或全部的服务。</p>
                <p class="xiaotou">五、用户使用规则</p>
                <p>1. 用户在使用系统过程中，应遵守国家相关法律和法规。</p>
                <p>2. 用户应遵守本平台网络服务协议的各项规定。</p>
                <p>3. 用户对以其账号发生的或通过其账号发生的一切活动和事件负全部法律责任。</p>
                <p>4. 用户不得为任何非法目的或利用本平台系统服务进行不正当活动。</p>
                <p>5. 用户不得对本平台系统服务任何部分或本平台系统服务中使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</p>
                <p class="xiaotou">六、免费声明</p>
                <p>1. 鉴于本科技系统使用网络服务的特殊性，不担保网络服务一定满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性和准确性也不作担保。</p>
                <p>2. 对于因电信系统或互联网网络故障、计算机故障、计算机系统问题或其它任何不可抗力原因而产生损失， 本平台系统不承担任何责任，但将尽力减少因此给用户造成的损失和影响。</p>
                <p>3. 本软件试用名额有限，一位用户只可以申请一次试用资格！</p>
              </div> -->
              <div class="shiyong_a"><p class="import">店名</p><input type="text" v-model="User.storeName" placeholder="请输入品牌名称"><span></span></div>
              <div class="shiyong_a">
                <p>地址</p>
                <select @change="sel('city')" v-model="User.storeProvince">
                  <option v-for="item in provinceList" :key="item.provinceCode" :value="item.provinceName">{{item.provinceName}}</option>
                </select>
                <select @change="sel('county')" v-model="User.storeCity">
                  <option v-for="item in cityList" :key="item.cityCode" :value="item.cityName">{{item.cityName}}</option>
                </select>
                <select v-model="User.storeCounty">
                  <option v-for="item in countyList" :key="item.countyCode" :value="item.countyName">{{item.countyName}}</option>
                </select>
              </div>
              <div class="shiyong_a"><p class="import">主账号</p><input type="text" v-model="User.userName" placeholder="6-20位的账号" @blur="testUser" maxlength="20"><span ref="three"></span></div>
              <div class="shiyong_a"><p class="import">密码</p><input type="password" v-model="User.passWord" placeholder="6-20位的密码" @blur="outBlur"><span ref="one"></span></div>
              <div class="shiyong_a"><p class="import">重复密码</p><input type="password" v-model="User.passWordTwo" placeholder="请再次输入密码" @blur="outBlur"><span ref="two"></span></div>
              <div class="shiyong_a"><p class="import">负责人</p><input type="text" v-model="User.xingming" placeholder="请输入联系人" maxlength="5"></div>
              <div class="shiyong_a"><p class="import">联系手机</p><input type="text" v-model="User.phone" placeholder="请输入联系人手机" maxlength="11"></div>
              <!--<div class="shiyong_a"><p>推荐码</p><input type="text" v-model="User.tuijianrenId" placeholder="请输入推荐码" style="width:135px"><small>*正式开通后赠送1个月使用时间</small></div>-->
              <div class="shiyong_b"><button type="button" @click="sub">申请试用</button><button type="button" class="reset" @click="$router.push('/')">返回登录</button></div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="shiyong_bj">
  <h1>东都多功能MMS系统试用</h1>
  
</div>

</template>

<script>
import {city} from "@/assets/js/city"
import axios from '@/http/axios'
import {testStr} from '@/utils/util'
export default {
  name: 'reg',
  data() {
    return {
      provinceList:[],
      cityList:[],
      countyList:[],
      User:{},
      subON:true,
      reg:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
    }
  },
  created() {this.init()},
  components: {},
  mounted() {},
  methods:{
    testUser(){
      if(this.User.userName&&this.User.userName.length>5&&this.User.userName.length<21){
        $(this.$refs.three).text("")
        axios.post('/reg/testUser',{userName:this.User.userName}).then((res) => {
          if(res.code===5000){
            this.subON=true
          }else{
            this.subON=false
            $(this.$refs.three).text(res.msg)
          }
        })
      }else{
        this.subON=false
        $(this.$refs.three).text("账号长度应6-20位")
      }
    },
    outBlur(e){
      let vam=$(e.target)
      if(testStr(vam.val())){
        vam.siblings("span").text(`${vam.siblings("p").text()}不能为空`)
        vam.addClass('err')
        return false
      }
      if(this.User.passWord.length<6){
        vam.siblings("span").text(`${vam.siblings("p").text()}最少6位`)
        vam.addClass('err')
        return false
      }
      if(this.User.passWord!=this.User.passWordTwo){
        vam.siblings("span").text("两次密码不一致")
        vam.addClass('err')
        return false
      }else{
        $(this.$refs.one).text("").siblings("input").removeClass('err')
        $(this.$refs.two).text("").siblings("input").removeClass('err')
      }
    },
    sel(str){
      if(str==="city"){
        this.cityList=city.filter(e=>e.provinceName==this.User.storeProvince)[0].cities 
        this.countyList=[]
      }else if(str==="county"){
        this.countyList=this.cityList.filter(e=>e.cityName===this.User.storeCity)[0].counties
      }
    },
    init(){
      this.provinceList=city.map((e)=>{
        const {provinceCode,provinceName}=e
        return {provinceCode,provinceName}
      })
    },
    sub(){
      if(this.subON){
        if(testStr(this.User.storeName)){
          this.$Mesg({content: '店名(公司名称)不能为空！',errOrSuc: 'war'})
          return
        }
        if(testStr(this.User.passWord)){
          this.$Mesg({content: '密码不能为空！',errOrSuc: 'war'})
          return
        }
        if(testStr(this.User.xingming)){
          this.$Mesg({content: '负责人姓名不能为空！',errOrSuc: 'war'})
          return
        }
        if(testStr(this.User.phone)||!this.reg.test(this.User.phone)){
          this.$Mesg({content: '负责人手机不正确！',errOrSuc: 'war'})
          return
        }
        axios.post('/reg/regAdd',this.User).then((res) => {
          if(res.code===5000){
            this.$Mesg({content:"注册成功,您获得为期1个月VIP试用期。移动端请用小程序（东都MMS）登录"})
            this.$router.push('/')
          }else{
            this.$Mesg({content: res.msg,errOrSuc: 'err'})
          }
        })
      }
    },
  }
}
</script>
